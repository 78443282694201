// Generated by Framer (58a021c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vdI2XN3EF"];

const variantClassNames = {vdI2XN3EF: "framer-v-zznuzl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "vdI2XN3EF", title: YFc6F6Goe = "Eu envie um link mágico para você no email: ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vdI2XN3EF", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5BpyQ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-zznuzl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vdI2XN3EF"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0Flb25payBCb2xk", "--framer-font-family": "\"Aeonik Bold\", sans-serif", "--framer-font-size": "24px", "--framer-line-height": "100%", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Eu envie um link mágico para você no email: </motion.p></React.Fragment>} className={"framer-59ka5r"} data-framer-name={"Por favor digite seu email"} fonts={["CUSTOM;Aeonik Bold"]} layoutDependency={layoutDependency} layoutId={"qlz1ZZskv"} style={{"--extracted-r6o4lv": "rgb(77, 86, 100)", "--framer-paragraph-spacing": "0px"}} text={YFc6F6Goe} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5BpyQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5BpyQ .framer-wzozb8 { display: block; }", ".framer-5BpyQ .framer-zznuzl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 24px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-5BpyQ .framer-59ka5r { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5BpyQ .framer-zznuzl { gap: 0px; } .framer-5BpyQ .framer-zznuzl > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5BpyQ .framer-zznuzl > :first-child { margin-left: 0px; } .framer-5BpyQ .framer-zznuzl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 498.667
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"YFc6F6Goe":"title"}
 */
const FramerQvt5KRcfg: React.ComponentType<Props> = withCSS(Component, css, "framer-5BpyQ") as typeof Component;
export default FramerQvt5KRcfg;

FramerQvt5KRcfg.displayName = "Component/SimpleText";

FramerQvt5KRcfg.defaultProps = {height: 24, width: 498.667};

addPropertyControls(FramerQvt5KRcfg, {YFc6F6Goe: {defaultValue: "Eu envie um link mágico para você no email: ", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerQvt5KRcfg, [{family: "Aeonik Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Qvt5KRcfg:default", url: "assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf"}, url: new URL("assets/NESCaYIQ0a59WC034Nrdje8WPUM.ttf", import.meta.url).href}])